import React, { useCallback, useMemo, useState } from 'react';
import {
  DocumentCategoryResponse,
  GetDocumentCategoriesResponse,
} from '../../metadata/documentCategory/GetDocumentCategoriesResponse';
import { find } from 'lodash';
import { Validator } from 'fluentvalidation-ts';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { haveAllowedFileExtension, notExceedSizeInBytes } from '../../validation/fileValidation';
import { notBeNull } from '../../validation/commonValidation';
import { notBeEmpty } from '../../validation/stringValidation';
import {
  DocumentTypeResponse,
  GetDocumentTypesResponse,
} from '../../metadata/documentType/GetDocumentTypesResponse';
import { Form } from '../../../infrastructure/forms/common/Form';
import { DocumentCategorySelectField } from '../../metadata/documentCategory/DocumentCategorySelectField';
import { TextAreaField } from '../../../infrastructure/forms/fields/TextAreaField';
import { DocumentTypeSelectField } from '../../metadata/documentType/DocumentTypeSelectField';
import { CompanySelectField } from '../../metadata/company/CompanySelectField';
import { FilePickerField } from '../../../infrastructure/forms/fields/FilePickerField';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { Formik, FormikHelpers } from 'formik';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { useMultipartApiRequest } from '../../../infrastructure/api/useMultipartApiRequest';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { defaultDocumentCategorySelectSettingsForUpload } from '../../metadata/documentCategory/DocumentCategorySelect';
import { defaultCompanySelectSettings } from '../../metadata/company/CompanySelectSettings';
import { InvestorSelectField } from '../../metadata/investor/InvestorSelectField';
import { DocumentTypeSelectContainer } from './DocumentTypeSelectContainer';

type Props = {
  allowedFileExtensions: Array<string>;
  maximumUploadSizeInBytes: number;
};

export const ConsolidatedInvestorUploadDocumentForm = ({
  allowedFileExtensions,
  maximumUploadSizeInBytes,
}: Props) => {
  const { translate } = useInternationalisation();

  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);

  const [success, setSuccess] = useState(false);

  const [documentCategoriesResponse, setDocumentCategoriesResponse] =
    useState<GetDocumentCategoriesResponse | null>(null);

  const findDocumentCategoryInResponse = useCallback(
    (documentCategoryId: number | null): DocumentCategoryResponse | null =>
      findDocumentCategory(
        documentCategoriesResponse?.documentCategories || [],
        documentCategoryId
      ),
    [documentCategoriesResponse]
  );

  const [documentTypesResponse, setDocumentTypesResponse] =
    useState<GetDocumentTypesResponse | null>(null);

  const shouldShowDocumentTypeDropdown = (
    documentCategoryId: number | null,
    investorId: number | null
  ): boolean => {
    const documentCategory = findDocumentCategoryInResponse(documentCategoryId);
    return (
      investorId != null &&
      documentCategory?.categoryType === 'AML' &&
      documentTypesResponse != null &&
      documentTypesResponse.documentTypes?.length > 0
    );
  };

  const shouldShowCompanyDropdown = (documentCategoryId: number | null): boolean => {
    const documentCategory = findDocumentCategoryInResponse(documentCategoryId);
    return documentCategory == null || documentCategory.categoryType !== 'AML';
  };

  const documentCategories = documentCategoriesResponse?.documentCategories;
  const documentTypes = documentTypesResponse?.documentTypes;

  const validator = useMemo(
    () =>
      new ConsolidatedInvestorUploadDocumentFormValidator(
        translate,
        allowedFileExtensions,
        maximumUploadSizeInBytes,
        documentCategories || [],
        documentTypes || []
      ),
    [translate, allowedFileExtensions, maximumUploadSizeInBytes, documentCategories, documentTypes]
  );

  const uploadRequest = useMultipartApiRequest<ConsolidatedInvestorUploadDocumentCommand>(
    '/api/documents/ConsolidatedInvestorUploadDocument'
  );

  const onSubmit = (
    formModel: ConsolidatedInvestorUploadDocumentFormModel,
    formikHelpers: FormikHelpers<ConsolidatedInvestorUploadDocumentFormModel>
  ) => {
    const command: ConsolidatedInvestorUploadDocumentCommand = {
      documentCategoryId: assertNotNull(formModel.documentCategoryId, 'documentCategoryId'),
      description: formModel.description,
      documentTypeCheckId: shouldShowDocumentTypeDropdown(
        formModel.documentCategoryId,
        formModel.investorId
      )
        ? assertNotNull(formModel.documentTypeCheckId, 'documentTypeCheckId')
        : null,
      companyId: shouldShowCompanyDropdown(formModel.documentCategoryId)
        ? assertNotNull(formModel.companyId, 'companyId')
        : null,
      investorId: formModel.investorId,
      documentFile: assertNotNull(formModel.documentFile[0], 'documentFile'),
    };

    uploadRequest.makeRequest({
      request: command,
      onSuccess: () => setSuccess(true),
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  if (filterLoadError != null) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {filterLoadError}
      </Alert>
    );
  }

  if (success) {
    return (
      <Alert alertType="positive" header={translate('pages.uploadDocument.successMessage.header')}>
        {translate('pages.uploadDocument.successMessage.body')}
      </Alert>
    );
  }

  return (
    <Panel>
      <Formik<ConsolidatedInvestorUploadDocumentFormModel>
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={validator.validate}
      >
        {(formikProps) => (
          <Form>
            <InvestorSelectField
              fieldName="investorId"
              label={translate('pages.uploadDocument.labels.investor')}
              onError={setFilterLoadError}
              settings={{ forTransacting: false, includeAll: false }}
              defaultToFirstOption={true}
            />
            <DocumentCategorySelectField
              settings={defaultDocumentCategorySelectSettingsForUpload}
              fieldName="documentCategoryId"
              label={translate('pages.uploadDocument.labels.documentCategory')}
              onLoaded={setDocumentCategoriesResponse}
              onError={setFilterLoadError}
              defaultToFirstOption={true}
            />
            <TextAreaField
              fieldName="description"
              label={translate('pages.uploadDocument.labels.description')}
            />
            <DocumentTypeSelectContainer
              visibility={
                shouldShowDocumentTypeDropdown(
                  formikProps.values.documentCategoryId,
                  formikProps.values.investorId
                )
                  ? 'visible'
                  : 'hidden'
              }
            >
              <DocumentTypeSelectField
                fieldName="documentTypeCheckId"
                label={translate('pages.uploadDocument.labels.documentType')}
                onError={setFilterLoadError}
                onLoaded={setDocumentTypesResponse}
                settings={{ investorId: formikProps.values.investorId }}
                defaultToFirstOption={true}
              />
            </DocumentTypeSelectContainer>
            {shouldShowCompanyDropdown(formikProps.values.documentCategoryId) && (
              <CompanySelectField
                fieldName="companyId"
                label={translate('pages.uploadDocument.labels.company')}
                onError={setFilterLoadError}
                settings={{
                  ...defaultCompanySelectSettings,
                  documentCategoryId: formikProps.values.documentCategoryId,
                  amlCheckId: null,
                }}
                defaultToFirstOption={true}
              />
            )}
            <FilePickerField
              fieldName="documentFile"
              allowMultiple={false}
              allowedFileTypes={allowedFileExtensions}
              label={translate('pages.uploadDocument.labels.documentFile')}
              disabled={allowedFileExtensions.length === 0 || maximumUploadSizeInBytes === 0}
            />
            <ButtonRow rightAligned={true} withMarginTop={true}>
              <SubmitButton
                submittingText={translate('pages.uploadDocument.submitButton.submittingText')}
              >
                {translate('pages.uploadDocument.submitButton.text')}
              </SubmitButton>
            </ButtonRow>
            {uploadRequest.state.error && (
              <Alert alertType="negative" header={translate('errors.apology')} withMarginTop={true}>
                {uploadRequest.state.error}
              </Alert>
            )}
          </Form>
        )}
      </Formik>
    </Panel>
  );
};

const findDocumentCategory = (
  documentCategories: Array<DocumentCategoryResponse>,
  documentCategoryId: number | null
): DocumentCategoryResponse | null =>
  find(
    documentCategories,
    (documentCategory) => documentCategory.documentCategoryId === documentCategoryId
  ) || null;

export type ConsolidatedInvestorUploadDocumentFormModel = {
  documentCategoryId: number | null;
  description: string;
  documentTypeCheckId: number | null;
  companyId: number | null;
  investorId: number | null;
  documentFile: Array<File>; // Needs to be an array as that's what the file picker exposes
};

export type ConsolidatedInvestorUploadDocumentCommand = {
  documentCategoryId: number;
  description: string;
  documentTypeCheckId: number | null;
  companyId: number | null;
  investorId: number | null;
  documentFile: File;
};

const initialFormValues: ConsolidatedInvestorUploadDocumentFormModel = {
  documentCategoryId: null,
  description: '',
  documentTypeCheckId: null,
  companyId: null,
  investorId: null,
  documentFile: [],
};

class ConsolidatedInvestorUploadDocumentFormValidator extends Validator<ConsolidatedInvestorUploadDocumentFormModel> {
  constructor(
    translate: TranslateFunction,
    allowedFileExtensions: Array<string>,
    maximumUploadSizeInBytes: number,
    documentCategories: Array<DocumentCategoryResponse>,
    documentTypes: Array<DocumentTypeResponse>
  ) {
    super();

    this.ruleFor('documentCategoryId').must(notBeNull(translate));

    this.ruleFor('description').must(notBeEmpty(translate));

    this.ruleFor('documentTypeCheckId')
      .must(notBeNull(translate))
      .when(
        (formModel) =>
          documentTypes.length > 0 &&
          formModel.documentCategoryId != null &&
          findDocumentCategory(documentCategories, formModel.documentCategoryId)?.categoryType ===
            'AML'
      );

    this.ruleFor('companyId')
      .must(notBeNull(translate))
      .when(
        (formModel) =>
          formModel.documentCategoryId == null ||
          findDocumentCategory(documentCategories, formModel.documentCategoryId)?.categoryType !==
            'AML'
      );

    this.ruleFor('investorId').must(notBeNull(translate));

    this.ruleFor('documentFile')
      .must((files) => files.length === 1)
      .withMessage(translate('validation.requiredField'))
      .must(haveAllowedFileExtension(translate, allowedFileExtensions))
      .must(notExceedSizeInBytes(translate, maximumUploadSizeInBytes));
  }
}
