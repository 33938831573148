import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { ReactNode, useEffect, useState } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { LineGraph, LineGraphDataPoint } from '../../../infrastructure/charts/LineGraph';
import {
  IsoDatestamp,
  toDateFromIsoDatestamp,
  toIsoDatestampFromDate,
} from '../../../helpers/dateTimeHelpers';
import {
  EquityAttributionSelect,
  EquityAttributionSelectOptionValue,
} from '../../metadata/equityAttribution/EquityAttributionSelect';
import { mapEquityAttributionDropdownOptionsResponseToSelectOptions } from '../../metadata/equityAttribution/GetEquityAttributionDropdownOptionsResponse';
import { InvalidEquityAttributionId } from '../../metadata/idConstants';
import { FieldError } from '../../../infrastructure/forms/common/FieldError';
import { ComponentResponse } from '../DashboardComponent';

export const fundPerformanceGraphComponentName = 'Fund Performance';

export const FundPerformanceGraph = () => {
  const getRequest = useGetJson<
    GetDataForFundPerformanceGraphQuery,
    GetDataForFundPerformanceGraphResponse
  >('api/dashboards/GetDataForFundPerformanceGraph');

  const { companyId, fromDate } = useDashboardFilters();
  const [equityAttributionId, setEquityAttributionId] =
    useState<EquityAttributionSelectOptionValue>(null as EquityAttributionSelectOptionValue);
  const [selectError, setSelectError] = useState<string | null>(null);

  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
          equityAttributionId: equityAttributionId ?? InvalidEquityAttributionId,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate, equityAttributionId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <FundPerformanceGraphComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
          equityAttributionId={equityAttributionId}
          companyId={companyId}
          setEquityAttributionId={setEquityAttributionId}
          selectError={selectError}
          setSelectError={setSelectError}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForFundPerformanceGraphResponse;
  showLoadingOverlay: boolean;
  equityAttributionId: EquityAttributionSelectOptionValue;
  setEquityAttributionId: (value: EquityAttributionSelectOptionValue) => void;
  companyId: number | null;
  selectError: string | null;
  setSelectError: (value: string | null) => void;
};

const FundPerformanceGraphComponent = (props: ComponentProps) => {
  const { translate, formatNumber, formatDate } = useInternationalisation();

  const graphData: Array<LineGraphDataPoint> = props.response.dataPoints.map((dataPoint) => ({
    x: toDateFromIsoDatestamp(dataPoint.label),
    y: dataPoint.value,
  }));

  const filterSetContent: ReactNode = (
    <div>
      <EquityAttributionSelect
        settings={{
          employeeCounterpartId: null,
          companyId: props.companyId,
          includeBlank: false,
        }}
        value={props.equityAttributionId}
        onChange={props.setEquityAttributionId}
        onError={props.setSelectError}
        onLoaded={(response) => {
          props.setSelectError(null);
          props.setEquityAttributionId(
            mapEquityAttributionDropdownOptionsResponseToSelectOptions(response)[0]?.value ?? null
          );
        }}
      />
      {props.selectError && (
        <FieldError fieldName={'FundPerformanceGraph'} children={props.selectError} />
      )}
    </div>
  );

  return (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.fundPerformanceGraph.title')}
      showLoadingOverlay={props.showLoadingOverlay}
      filterSet={filterSetContent}
      showNoDataMessage={props.response.noDataMessage}
    >
      <LineGraph
        exportFilename={translate('pages.dashboard.components.fundPerformanceGraph.exportName')}
        dataPoints={graphData}
        height="stretch"
        xAxisType="datetime"
        yAxisOptions={{
          labels: {
            formatter() {
              return `${this.value}`;
            },
          },
        }}
        tooltipOptions={{
          headerFormat: '',
          pointFormatter() {
            const formattedX = formatDate(toIsoDatestampFromDate(new Date(this.x)));
            const formattedY =
              this.y === undefined ? '' : formatNumber(this.y, { decimalPlaces: 2 });
            return `<b>${formattedX}</b><br/>${formattedY}%`;
          },
        }}
      />
    </DashboardComponentLayout>
  );
};

type GetDataForFundPerformanceGraphQuery = {
  companyId: number;
  runDate: string;
  equityAttributionId: number;
};

export type GetDataForFundPerformanceGraphResponse = ComponentResponse & {
  dataPoints: Array<{
    value: number;
    label: IsoDatestamp;
  }>;
};
