import React, { createContext } from 'react';
import { GetDocumentsResponse } from './Documents';
import { IsoDatestamp } from '../../helpers/dateTimeHelpers';

type DocumentsPageContextValue = {
  latestResponse: GetDocumentsResponse | null;
  inProgress: boolean;
  error: string | null;

  documentCategoryId: number | null;
  setDocumentCategoryId: (documentCategoryId: number | null) => void;

  investorId: number | null;
  setInvestorId: (investorId: number | null) => void;

  companyId: number | null;
  setCompanyId: (companyId: number | null) => void;

  runDate: IsoDatestamp | null;
  setRunDate: (runDate: IsoDatestamp | null) => void;

  inputBoundSearchString: string;
  onChangeSearchInput: (changeEvent: React.ChangeEvent<HTMLInputElement>) => void;

  resetFilters: () => void;

  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;

  resultsPerPage: number;
  setResultsPerPage: (resultsPerPage: number) => void;

  setFilterLoadError: (error: string) => void;

  loadData: () => void;
};

const throwNotInitialisedError = () => {
  throw new Error('Documents Page Context is not yet initialised');
};

export const DocumentsPageContext = createContext<DocumentsPageContextValue>({
  latestResponse: null,
  inProgress: false,
  error: null,
  documentCategoryId: null,
  setDocumentCategoryId: throwNotInitialisedError,
  investorId: null,
  setInvestorId: throwNotInitialisedError,
  companyId: null,
  setCompanyId: throwNotInitialisedError,
  runDate: null,
  setRunDate: throwNotInitialisedError,
  inputBoundSearchString: '',
  onChangeSearchInput: throwNotInitialisedError,
  resetFilters: throwNotInitialisedError,
  pageNumber: 1,
  setPageNumber: throwNotInitialisedError,
  resultsPerPage: 1,
  setResultsPerPage: throwNotInitialisedError,
  setFilterLoadError: throwNotInitialisedError,
  loadData: throwNotInitialisedError,
});
