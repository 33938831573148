import { useGetJson } from '../../../infrastructure/api/useGetJson';
import {
  getIsoDatestampFromUnknownFormatDateTimeString,
  IsoDatestamp,
} from '../../../helpers/dateTimeHelpers';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { ReactNode, useEffect, useState } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import {
  Table,
  TBody,
  Th,
  Tr,
  TdMobile,
  THeadMobile,
} from '../../../infrastructure/interface/tables/Table';
import { AppLink } from '../../../infrastructure/interface/components/AppLink';
import { Checkbox } from '../../../infrastructure/interface/forms/Checkbox';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { ComponentResponse } from '../DashboardComponent';
import {
  Modal,
  ModalButtonRow,
  ModalHeader,
} from '../../../infrastructure/interface/components/Modal';
import { NegativeButton } from '../../../infrastructure/interface/buttons/NegativeButton';
import { SecondaryButton } from '../../../infrastructure/interface/buttons/SecondaryButton';
import styled from 'styled-components/macro';
import { getAlertBorderColour } from '../../../infrastructure/interface/components/Alert';
import { spacing8 } from '../../../styling/design/spacing';

export const aumHistoryTableName = 'AUM History Table';
export const AumHistoryTable = () => {
  const endpointUrl = 'api/dashboards/GetDataForAumHistoryTable';
  const getRequest = useGetJson<GetAumHistoryQuery, GetAumHistoryResponse>(endpointUrl);

  const { companyId, fromDate } = useDashboardFilters();
  const makeRequest = () => {
    if (companyId != null && fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          companyId,
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [companyId, fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <AumHistoryTableComponent response={response} showLoadingOverlay={showLoadingOverlay} />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetAumHistoryResponse;
  showLoadingOverlay: boolean;
};

const formatDate = (str: string) => {
  const event = new Date(str);
  return event.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' });
};

const AumHistoryTableComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate } = useInternationalisation();
  const headerText = translate('pages.dashboard.components.aumHistoryTable.title', {
    localCurrencyCode: response.data[0]?.localCurrencyCode ?? 'Local Currency',
  });

  const approvalColumnContentForRow = (row: AumHistoryRow): string | ReactNode | null => {
    const shouldShowApprovalColumn = response.shouldShowApprovalColum;

    if (row.navStatus === 'Unknown') return 'N/A';
    if (shouldShowApprovalColumn && row.isApproved == null) return 'N/A';
    if (!shouldShowApprovalColumn && row.navStatus !== 'Unknown') return null;
    return <NavStatusApprovalCheckbox aumHistoryRow={row} />;
  };

  const headers = {
    date: translate('pages.dashboard.components.aumHistoryTable.headerNames.date'),
    navStatus: translate('pages.dashboard.components.aumHistoryTable.headerNames.navStatus'),
    approve: translate('pages.dashboard.components.aumHistoryTable.headerNames.approve'),
    aum: translate('pages.dashboard.components.aumHistoryTable.headerNames.aum'),
    contributions: translate(
      'pages.dashboard.components.aumHistoryTable.headerNames.contributions'
    ),
    withdrawals: translate('pages.dashboard.components.aumHistoryTable.headerNames.withdrawals'),
    distributions: translate(
      'pages.dashboard.components.aumHistoryTable.headerNames.distributions'
    ),
    inv: translate('pages.dashboard.components.aumHistoryTable.headerNames.inv'),
  };

  return (
    <DashboardComponentLayout
      headerText={headerText}
      showLoadingOverlay={showLoadingOverlay}
      showNoDataMessage={response.noDataMessage}
    >
      {/* eslint-disable-next-line react/jsx-no-undef */}

      <Table compact={true} headerPadding={'2px'}>
        <THeadMobile>
          <Tr>
            <Th>{headers.date}</Th>
            <Th>{headers.navStatus}</Th>
            <Th>{headers.approve}</Th>
            <Th>{headers.aum}</Th>
            <Th>{headers.contributions}</Th>
            <Th>{headers.withdrawals}</Th>
            <Th>{headers.distributions}</Th>
            <Th> {headers.inv}</Th>
          </Tr>
        </THeadMobile>
        <TBody>
          {response.data.map((row, index) => (
            <Tr key={index}>
              <TdMobile data-label={headers.date}>
                {row.valueDateLink != null ? (
                  <AppLink to={row.valueDateLink}>{formatDate(row.date)}</AppLink>
                ) : (
                  formatDate(row.date)
                )}
              </TdMobile>
              <TdMobile data-label={headers.navStatus}>{row.navStatus}</TdMobile>
              <TdMobile data-label={headers.approve}>{approvalColumnContentForRow(row)}</TdMobile>
              <TdMobile data-label={headers.aum}>
                {parseFloat(row.aum.toFixed(0)).toLocaleString()}
              </TdMobile>
              <TdMobile data-label={headers.contributions}>
                {row.contributionsLink != null ? (
                  <AppLink to={row.contributionsLink}>
                    {row.contributions?.toLocaleString()}
                  </AppLink>
                ) : (
                  row.contributions
                )}
              </TdMobile>
              <TdMobile data-label={headers.withdrawals}>
                {row.withdrawalsLink != null ? (
                  <AppLink to={row.withdrawalsLink}>{row.withdrawals?.toLocaleString()}</AppLink>
                ) : (
                  row.withdrawals?.toLocaleString()
                )}
              </TdMobile>
              <TdMobile data-label={headers.distributions}>
                {row.distributionsLink != null ? (
                  <AppLink to={row.distributionsLink}>
                    {row.distributions?.toLocaleString()}
                  </AppLink>
                ) : (
                  row.distributions?.toLocaleString()
                )}
              </TdMobile>
              <TdMobile data-label={headers.inv}>
                <AppLink to={row.invColumnLink}>{row.inv}</AppLink>
              </TdMobile>
            </Tr>
          ))}
          <Tr></Tr>
        </TBody>
      </Table>
    </DashboardComponentLayout>
  );
};

const NavStatusApprovalCheckbox = ({ aumHistoryRow }: { aumHistoryRow: AumHistoryRow }) => {
  const { translate } = useInternationalisation();
  const endpointUrl = 'api/nav/SetNavApprovalStatus';
  const request = usePostJson<SetNavStatusApprovalQuery, SetNavStatusApprovalResponse>(endpointUrl);
  const [isChecked, setIsChecked] = useState<boolean>(aumHistoryRow.isApproved as boolean);
  const [isDisabled, setCheckboxDisabled] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [pendingCheckState, setPendingCheckstate] = useState<boolean | null>(null);

  useEffect(() => {
    // useEffect hook will ensure the correct value is shown in the checkbox when ever the aumHistory row changes between funds.
    setIsChecked(aumHistoryRow.isApproved as boolean);
  }, [aumHistoryRow.companyId, aumHistoryRow.isApproved]);

  const handleChangeConfirmed = () => {
    setIsConfirmModalOpen(false);
    setCheckboxDisabled(true);
    request.makeRequest({
      requestBody: {
        companyId: aumHistoryRow.companyId,
        valuationDate: getIsoDatestampFromUnknownFormatDateTimeString(aumHistoryRow.date),
        managerApproval: pendingCheckState as boolean,
      },
      onSuccess: (response) => {
        setIsChecked(pendingCheckState as boolean);
        setCheckboxDisabled(false);
      },
      onFailure: (error: string) => {
        alert(error); //Todo: replace with nicer modal dialog.
        setCheckboxDisabled(false);
      },
    });
  };

  const handleCheckboxValueChange = (value: boolean): void => {
    setPendingCheckstate(value);
    setIsConfirmModalOpen(true);
  };

  const handleDismissModal = (): void => {
    setIsConfirmModalOpen(false);
  };

  return (
    <>
      <NavConfirmModal isOpen={isConfirmModalOpen} onRequestClose={handleDismissModal}>
        <ModalHeader
          title={translate('pages.dashboard.components.aumHistoryTable.navApproval.confirmHeader')}
        />
        {translate('pages.dashboard.components.aumHistoryTable.navApproval.confirmBody')}
        <ModalButtonRow>
          <SecondaryButton onClick={handleDismissModal}>
            {translate('pages.dashboard.components.aumHistoryTable.navApproval.noButtonText')}
          </SecondaryButton>
          <NegativeButton onClick={handleChangeConfirmed}>
            {translate('pages.dashboard.components.aumHistoryTable.navApproval.yesButtonText')}
          </NegativeButton>
        </ModalButtonRow>
      </NavConfirmModal>
      <Checkbox checked={isChecked} onChange={handleCheckboxValueChange} disabled={isDisabled} />
    </>
  );
};

type SetNavStatusApprovalQuery = {
  companyId: number;
  valuationDate: IsoDatestamp;
  managerApproval: boolean;
};
type SetNavStatusApprovalResponse = {};

type GetAumHistoryQuery = {
  companyId: number;
  runDate: IsoDatestamp;
};

type GetAumHistoryResponse = ComponentResponse & {
  shouldShowApprovalColum: boolean;
  data: Array<AumHistoryRow>;
};

type AumHistoryRow = {
  companyId: number;
  date: string;
  navStatus: string | null;
  isApproved?: boolean;
  aum: number;
  inv: number;
  invColumnLink: string;
  distributionsLink: string | null;
  contributionsLink: string | null;
  withdrawalsLink: string | null;
  valueDateLink: string | null;
  distributions: number | null;
  contributions: number | null;
  withdrawals: number | null;
  localCurrencyCode: string | null;
};

const NavConfirmModal = styled(Modal)`
  border-left-style: solid;
  border-left-width: ${spacing8};
  border-left-color: ${getAlertBorderColour('warning')};
`;
