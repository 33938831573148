import React, { useState } from 'react';
import { useInternationalisation } from '../../../../internationalisation/hooks/useInternationalisation';
import { useWindowTitle } from '../../../../infrastructure/hooks/useWindowTitle';
import { useDealNumberUrlParam } from '../../useDealNumberUrlParam';
import { useUploadIdUrlParam } from '../../useUploadIdUrlParam';
import {
  GetTransactionDetailResponse,
  useGetTransactionDetailRequest,
} from '../../useGetTransactionDetailRequest';
import { ApiRequestStateWrapper } from '../../../../infrastructure/api/ApiRequestStateWrapper';
import { assertNotNull } from '../../../../helpers/nullHelpers';
import { useOnMount } from '../../../../infrastructure/hooks/useOnMount';
import { CentredPaddedPage } from '../../../../styling/layout/PaddedPage';
import { Panel } from '../../../../infrastructure/interface/components/Panel';
import { Header1, Header3 } from '../../../../infrastructure/interface/components/Headers';
import {
  FieldAndValue,
  FieldAndValueWithSeparatorsContainer,
} from '../../../../infrastructure/interface/components/FieldAndValue';
import { IfUserHasRole } from '../../../authentication/UserRoles';
import { placeTransactionInvestorFieldUserRoles } from '../placeTransactionUserRoles';
import { transactionLockCode } from '../../../metadata/transactions/TransactionLock';
import { Alert } from '../../../../infrastructure/interface/components/Alert';
import { ConfirmTransactionActionButtons } from './ConfirmTransactionActionButtons';
import styled, { css } from 'styled-components/macro';
import { onMobile } from '../../../../styling/layout/screenBreakpoints';
import { spacing16, spacing32, spacing8 } from '../../../../styling/design/spacing';
import { MinimalButton } from '../../../../infrastructure/interface/buttons/MinimalButton';
import { ChevronLeftIcon } from '../../../../icons/icons';
import { useNavigate } from 'react-router';
import { usePostJson } from '../../../../infrastructure/api/usePostJson';
import { DefaultTransactionsPageLink } from '../../defaultTransactionsPageLink.function';

export const ConfirmTransaction = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.confirmTransaction.title'));

  const dealNumber = assertNotNull(useDealNumberUrlParam(), 'dealNumber');
  const uploadId = assertNotNull(useUploadIdUrlParam(), 'uploadId');

  const getTransactionDetailRequest = useGetTransactionDetailRequest();

  const makeGetTransactionDetailRequest = () =>
    getTransactionDetailRequest.makeRequest({
      queryParameters: {
        dealNumber: assertNotNull(dealNumber, 'dealNumber'),
        uploadId: assertNotNull(uploadId, 'uploadId'),
      },
    });

  useOnMount(() => {
    makeGetTransactionDetailRequest();
  });

  return (
    <ApiRequestStateWrapper
      apiRequestState={getTransactionDetailRequest.state}
      retry={makeGetTransactionDetailRequest}
    >
      {(response) => (
        <ConfirmTransactionComponent
          transactionDetails={response}
          uploadId={uploadId}
          dealNumber={dealNumber}
        />
      )}
    </ApiRequestStateWrapper>
  );
};

type ConfirmTransactionComponentProps = {
  transactionDetails: GetTransactionDetailResponse;
  uploadId: number;
  dealNumber: number;
};

const ConfirmTransactionComponent = ({
  transactionDetails,
  uploadId,
  dealNumber,
}: ConfirmTransactionComponentProps) => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const [confirmationSuccessful, setConfirmationSuccessful] = useState(false);

  const getFieldLabel = (translationKey: string) =>
    translate(`pages.confirmTransaction.fieldLabels.${translationKey}`);

  const onBackOrCancelClick = () => {
    if (confirmationSuccessful) {
      navigate(DefaultTransactionsPageLink());
    } else {
      deleteRequest({
        requestBody: {
          investorId: transactionDetails.investorId,
          uploadId: uploadId,
          dealNumber: dealNumber,
        },
        onSuccess: () => navigate('/transactions/place-transaction'),
        onFailure: () => navigate('/transactions/place-transaction'),
      });
    }
  };

  const { makeRequest: deleteRequest } = usePostJson<DeleteTransactionCommand, {}>(
    '/api/transactions/DeleteTransaction'
  );

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.confirmTransaction.header')}</Header1>
      <MinimalButton icon={<ChevronLeftIcon />} onClick={onBackOrCancelClick}>
        {translate('navigation.back')}
      </MinimalButton>
      <DetailsPanel>
        <PreambleContainer>{translate('pages.confirmTransaction.preamble')}</PreambleContainer>
        <Header3>{translate('pages.confirmTransaction.subHeader')}</Header3>
        <FieldAndValueWithSeparatorsContainer>
          <IfUserHasRole userRole={placeTransactionInvestorFieldUserRoles}>
            <FieldAndValue
              type="text"
              fieldLabel={getFieldLabel('investor')}
              value={transactionDetails.investorName}
            />
          </IfUserHasRole>
          <FieldAndValue
            type="text"
            fieldLabel={getFieldLabel('fund')}
            value={transactionDetails.fundName}
          />
          <FieldAndValue
            type="text"
            fieldLabel={getFieldLabel('transactionType')}
            value={transactionDetails.transactionTypeString}
          />
          <FieldAndValue
            type="date"
            fieldLabel={getFieldLabel('dealingDate')}
            value={transactionDetails.dealingDate}
          />
          <FieldAndValue
            type="text"
            fieldLabel={getFieldLabel('shareClassSeries')}
            value={transactionDetails.shareClassSeries}
          />
          {transactionDetails.companySwitch != null && (
            <FieldAndValue
              type="text"
              fieldLabel={getFieldLabel('companySwitch')}
              value={transactionDetails.companySwitch}
            />
          )}
          {transactionDetails.equityAttributionSwitch != null && (
            <FieldAndValue
              type="text"
              fieldLabel={getFieldLabel('equityAttributionSwitch')}
              value={transactionDetails.equityAttributionSwitch}
            />
          )}
          {transactionDetails.transactionLock === transactionLockCode.Amount &&
            transactionDetails.amount != null && (
              <FieldAndValue
                type="number"
                fieldLabel={translate('pages.placeTransaction.labels.amount', {
                  currencyCode: transactionDetails.shareClassSeriesCurrencyCode,
                })}
                value={transactionDetails.amount}
              />
            )}
          {transactionDetails.transactionLock === transactionLockCode.Shares &&
            transactionDetails.shares != null && (
              <FieldAndValue
                type="number"
                fieldLabel={getFieldLabel('shares')}
                value={transactionDetails.shares}
                options={{ decimalPlaces: transactionDetails.shareDecimals }}
              />
            )}
          {transactionDetails.feesString && (
            <FieldAndValue
              type="text"
              fieldLabel={getFieldLabel('fees')}
              value={transactionDetails.feesString}
            />
          )}
          {transactionDetails.transactionReference && (
            <FieldAndValue
              type="text"
              fieldLabel={getFieldLabel('yourReference')}
              value={transactionDetails.transactionReference}
            />
          )}
          {transactionDetails.additionalInstructions && (
            <FieldAndValue
              type="text"
              fieldLabel={getFieldLabel('additionalInstructions')}
              value={transactionDetails.additionalInstructions}
            />
          )}
        </FieldAndValueWithSeparatorsContainer>
      </DetailsPanel>
      {transactionDetails.showEqualisationDeficitCreditMessage && (
        <Alert alertType="notice" withMarginTop={true}>
          {translate('pages.confirmTransaction.equalisationDeficitCreditMessage')}
        </Alert>
      )}
      {transactionDetails.shareRegistryTradingMessageDisclaimer && (
        <Alert alertType="notice" withMarginTop={true}>
          {transactionDetails.shareRegistryTradingMessageDisclaimer}
        </Alert>
      )}
      {transactionDetails.warnings.length !== 0 && (
        <Alert alertType="warning" withMarginTop={true}>
          {transactionDetails.warnings.map((warning, index) => (
            <p key={index}>{warning}</p>
          ))}
        </Alert>
      )}
      <ConfirmTransactionActionButtons
        investorId={transactionDetails.investorId}
        uploadId={uploadId}
        dealNumber={dealNumber}
        onCancelClick={onBackOrCancelClick}
        onConfirmationSuccessful={() => setConfirmationSuccessful(true)}
      />
    </CentredPaddedPage>
  );
};

const DetailsPanel = styled(Panel)`
  margin-top: ${spacing32};

  ${onMobile(css`
    box-shadow: none;
    border-radius: 0;
    padding: ${spacing8};
    margin-top: 0;
  `)}
`;

const PreambleContainer = styled.div`
  padding-bottom: ${spacing32};

  ${onMobile(css`
    margin-bottom: ${spacing16};
  `)}
`;

type DeleteTransactionCommand = {
  uploadId: number;
  dealNumber: number;
  investorId: number | null;
};
