import React from 'react';
import {
  DocumentCategorySelect,
  DocumentCategorySelectOptionValue,
  DocumentCategorySelectSettings,
} from './DocumentCategorySelect';
import { GetDocumentCategoriesResponse } from './GetDocumentCategoriesResponse';
import {
  MetadataSingleSelectField,
  MetadataSingleSelectFieldComponentProps,
} from '../MetadataSingleSelectField';

type Props = MetadataSingleSelectFieldComponentProps<
  DocumentCategorySelectOptionValue,
  DocumentCategorySelectSettings,
  GetDocumentCategoriesResponse
>;

export const DocumentCategorySelectField = (props: Props) => (
  <MetadataSingleSelectField<
    DocumentCategorySelectOptionValue,
    DocumentCategorySelectSettings,
    GetDocumentCategoriesResponse
  >
    {...props}
    renderSelect={(metadataSingleSelectProps) => (
      <DocumentCategorySelect {...metadataSingleSelectProps} />
    )}
  />
);
