import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { GetDataForTransactionsQuery } from './types';
import { TransactionsContext } from './TransactionsContext';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { Modal, ModalHeader } from '../../infrastructure/interface/components/Modal';
import { useFileDownload } from '../../infrastructure/api/useFileDownload';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { ErrorMessage } from '../../infrastructure/interface/components/ErrorMessage';
import { ExcelFileIcon } from '../../icons/icons';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';

export const ExportTransactionsToExcelButton = () => {
  const { translate } = useInternationalisation();
  const { getUser } = useContext(AuthenticationContext);

  const {
    pageNumber,
    resultsPerPage,
    investorId,
    companyId,
    equityAttributionId,
    startDate,
    endDate,
    transactionTypes,
    agentId,
    dealerId,
    unapprovedOnly,
    response,
  } = useContext(TransactionsContext);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const openErrorModal = () => setShowErrorModal(true);
  const closeErrorModal = () => setShowErrorModal(false);

  const { downloadFile, inProgress, error } = useFileDownload<
    { query: GetDataForTransactionsQuery },
    undefined
  >({
    endpointUrl: '/api/transactions/ExportTransactionsToExcel',
    method: 'post',
  });

  const onClick = () => {
    const query: GetDataForTransactionsQuery = {
      pageNumber,
      resultsPerPage,
      unapprovedOnly,
      investorId,
      startDate,
      endDate,
      transactionTypes,
      companyId,
      equityAttributionId,
      agentId,
      dealerId,
    };

    downloadFile({
      fileName: `${getUser().name}_Transactions_${format(new Date(), 'yy-MM-dd')}.xlsx`,
      requestBody: { query },
      queryParameters: undefined,
      onError: () => openErrorModal(),
    });
  };

  return (
    <>
      <MinimalButton
        icon={<ExcelFileIcon />}
        onClick={onClick}
        disabled={inProgress || response == null || isEmpty(response.transactions)}
      >
        {inProgress
          ? translate('pages.transactions.exportButton.waitingText')
          : translate('pages.transactions.exportButton.text')}
      </MinimalButton>
      <Modal isOpen={showErrorModal} onRequestClose={closeErrorModal}>
        <ModalHeader
          title={translate('errors.apology')}
          withCloseButton={true}
          onRequestClose={closeErrorModal}
        />
        <ErrorMessage>{error}</ErrorMessage>
      </Modal>
    </>
  );
};
