import React, { useMemo } from 'react';
import { CentredPaddedPage, PaddedPage } from '../../styling/layout/PaddedPage';
import { IfUserHasRole, RequiresUserRole } from '../authentication/UserRoles';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { Alert } from '../../infrastructure/interface/components/Alert';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { ManagerUploadDocumentForm } from './upload/ManagerUploadDocumentForm';
import { ConsolidatedInvestorUploadDocumentForm } from './upload/ConsolidatedInvestorUploadDocumentForm';
import { InvestorUploadDocumentForm } from './upload/InvestorUploadDocumentForm';
import { BackButton } from '../../infrastructure/interface/components/BackButton';
import { spacing32 } from '../../styling/design/spacing';
import styled from 'styled-components/macro';

export const UploadDocument = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.uploadDocument.title'));

  return (
    <RequiresUserRole userRole={['Manager', 'Investor', 'Consolidated Investor']}>
      <UploadDocumentComponent />
    </RequiresUserRole>
  );
};

const UploadDocumentComponent = () => {
  const { translate } = useInternationalisation();

  const getValidationSettingsRequest = useGetJson<
    undefined,
    GetDocumentUploadValidationSettingsResponse
  >('/api/documents/GetDocumentUploadValidationSettings');

  useOnMount(() => {
    getValidationSettingsRequest.makeRequest();
  });

  const validationSettingsLoadError = getValidationSettingsRequest.state.error;

  const allowedFileExtensions = useMemo(
    () => getValidationSettingsRequest.state.response?.allowedFileExtensions ?? [],
    [getValidationSettingsRequest]
  );

  const maximumUploadSizeInBytes = useMemo(
    () => getValidationSettingsRequest.state.response?.maximumUploadSizeInBytes ?? 0,
    [getValidationSettingsRequest]
  );

  if (validationSettingsLoadError != null) {
    return (
      <PaddedPage>
        <Alert alertType="negative" header={translate('errors.apology')}>
          {validationSettingsLoadError}
        </Alert>
      </PaddedPage>
    );
  }

  return (
    <CentredPaddedPage>
      <Header1>{translate('pages.uploadDocument.header')}</Header1>
      <BackButton />
      <FormContainer>
        <IfUserHasRole userRole="Manager">
          <ManagerUploadDocumentForm
            allowedFileExtensions={allowedFileExtensions}
            maximumUploadSizeInBytes={maximumUploadSizeInBytes}
          />
        </IfUserHasRole>
        <IfUserHasRole userRole="Consolidated Investor">
          <ConsolidatedInvestorUploadDocumentForm
            allowedFileExtensions={allowedFileExtensions}
            maximumUploadSizeInBytes={maximumUploadSizeInBytes}
          />
        </IfUserHasRole>
        <IfUserHasRole userRole="Investor">
          <InvestorUploadDocumentForm
            allowedFileExtensions={allowedFileExtensions}
            maximumUploadSizeInBytes={maximumUploadSizeInBytes}
          />
        </IfUserHasRole>
      </FormContainer>
    </CentredPaddedPage>
  );
};

const FormContainer = styled.div`
  padding: ${spacing32} 0;
`;

export type GetDocumentUploadValidationSettingsResponse = {
  allowedFileExtensions: Array<string>;
  maximumUploadSizeInBytes: number;
};
