import React from 'react';
import {
  GetDocumentCategoriesResponse,
  mapGetDocumentCategoriesResponseToSelectOptions,
} from './GetDocumentCategoriesResponse';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';

export type DocumentCategorySelectOptionValue = number | null;

export type DocumentCategorySelectSettings = {
  includeAllCategory?: boolean;
  isForUpload?: boolean;
};

export const defaultDocumentCategorySelectSettings: DocumentCategorySelectSettings = {};
export const defaultDocumentCategorySelectSettingsForUpload: DocumentCategorySelectSettings = {
  isForUpload: true,
};

type Props = MetadataSingleSelectComponentProps<
  DocumentCategorySelectOptionValue,
  DocumentCategorySelectSettings,
  GetDocumentCategoriesResponse
>;

export const DocumentCategorySelect = ({ settings, ...metadataSingleSelectProps }: Props) => {
  const { translate } = useInternationalisation();

  const placeholder = translate('metadata.documentCategorySelect.placeholder');
  const loadingPlaceholder = translate('metadata.documentCategorySelect.loadingPlaceholder');

  return (
    <MetadataSingleSelect<
      DocumentCategorySelectOptionValue,
      DocumentCategorySelectSettings,
      GetDocumentCategoriesQuery,
      GetDocumentCategoriesResponse
    >
      endpointUrl="/api/documents/GetDocumentCategories"
      settings={settings}
      mapSettingsToQueryParameters={(settings) => settings}
      mapResponseToOptions={mapGetDocumentCategoriesResponseToSelectOptions}
      placeholder={placeholder}
      loadingPlaceholder={loadingPlaceholder}
      minWidthInPixels={350}
      {...metadataSingleSelectProps}
    />
  );
};

type GetDocumentCategoriesQuery = {
  includeAllCategory?: boolean;
  isForUpload?: boolean;
};
