import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { Header1 } from '../../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { HasUserRole, RequiresUserRole } from '../authentication/UserRoles';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { debounce, isEqual } from 'lodash';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { getIsoDatestampToday, IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { useUrlState } from '../../infrastructure/hooks/useUrlState';
import { DocumentsPageContext } from './DocumentsPageContext';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { DesktopDocumentsList } from './DesktopDocumentsList';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { MobileDocumentsList } from './MobileDocumentsList';
import { useOnMount } from '../../infrastructure/hooks/useOnMount';
import { GetDocumentUploadValidationSettingsResponse } from './UploadDocument';
import { ApiRequestStateWrapper } from '../../infrastructure/api/ApiRequestStateWrapper';
import { spacing16, spacing8 } from '../../styling/design/spacing';
import { AppLink } from '../../infrastructure/interface/components/AppLink';
import { UploadIcon } from '../../icons/icons';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import { defaultInitialResultsPerPageOption } from '../../infrastructure/interface/tables/PaginatedTable';
import { RequestFailedPage } from '../../infrastructure/api/RequestFailedPage';
import { NotificationsContext } from '../../styling/layout/sidebar/notifications/NotificationsContext';
import { notificationType } from '../../styling/layout/sidebar/notifications/NotificationType';
import { useSessionCompanyId } from '../../infrastructure/hooks/useSessionCompanyId';

export const documentUploadLinkTestId = 'document-upload-link';

type DocumentsFilterState = {
  pageNumber: number;
  resultsPerPage: number;
  documentCategoryId: number | null;
  investorId: number | null;
  companyId: number | null;
  runDate: IsoDatestamp | null;
  searchString: string;
};

const defaultDocumentsFilterState: DocumentsFilterState = {
  pageNumber: 1,
  resultsPerPage: defaultInitialResultsPerPageOption,
  documentCategoryId: null,
  investorId: null,
  companyId: null,
  runDate: getIsoDatestampToday(),
  searchString: '',
};

const resetDocumentsFilterState: DocumentsFilterState = {
  pageNumber: 1,
  resultsPerPage: defaultInitialResultsPerPageOption,
  documentCategoryId: 0,
  investorId: 0,
  companyId: -1,
  runDate: getIsoDatestampToday(),
  searchString: '',
};

export const Documents = () => (
  <RequiresUserRole userRole={allNonAdminUserRoles}>
    <UploadEnabledAwareDocumentsComponent />
  </RequiresUserRole>
);

const UploadEnabledAwareDocumentsComponent = () => {
  const { notifications, clearAllNotificationsByType } = useContext(NotificationsContext);

  useEffect(() => {
    if (notifications.some((notification) => notification.type === notificationType.Document)) {
      clearAllNotificationsByType(notificationType.Document);
    }
  }, [notifications]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDocumentValidationSettingsRequest = useGetJson<
    undefined,
    GetDocumentUploadValidationSettingsResponse
  >('/api/documents/GetDocumentUploadValidationSettings');

  useOnMount(() => {
    getDocumentValidationSettingsRequest.makeRequest();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getDocumentValidationSettingsRequest.state}>
      {(response) => (
        <HasUserRole userRole={['Manager', 'Investor', 'Consolidated Investor']}>
          {(hasUploadPermission) => (
            <DocumentsComponent
              isUploadEnabled={
                hasUploadPermission &&
                response.allowedFileExtensions.length > 0 &&
                response.maximumUploadSizeInBytes > 0
              }
            />
          )}
        </HasUserRole>
      )}
    </ApiRequestStateWrapper>
  );
};

type DocumentsComponentProps = {
  isUploadEnabled: boolean;
};

const DocumentsComponent = ({ isUploadEnabled }: DocumentsComponentProps) => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.documents.title'));

  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);

  const {
    state: filterState,
    setState: setFilterState,
    updateState,
    useUrlStateValue,
  } = useUrlState<DocumentsFilterState>(defaultDocumentsFilterState, {
    pageNumber: { type: 'number' },
    resultsPerPage: { type: 'number' },
    documentCategoryId: { type: 'number', isNullable: true },
    investorId: { type: 'number', isNullable: true },
    companyId: { type: 'number', isNullable: true },
    runDate: { type: 'string', isNullable: true },
    searchString: { type: 'string' },
  });

  const [pageNumber, setPageNumber] = useUrlStateValue('pageNumber');

  const resultsPerPage = filterState.resultsPerPage;
  const setResultsPerPage = (resultsPerPage: number) => updateState({ resultsPerPage });

  const getDocumentsApiRequest = useGetJson<GetDocumentsQuery, GetDocumentsResponse>(
    '/api/documents/GetDocuments'
  );

  const documentCategoryId = filterState.documentCategoryId;
  const setDocumentCategoryId = (documentCategoryId: number | null) =>
    updateState({
      documentCategoryId,
      pageNumber: 1,
    });

  const investorId = filterState.investorId;
  const setInvestorId = (investorId: number | null) => updateState({ investorId, pageNumber: 1 });

  const { sessionCompanyId } = useSessionCompanyId();
  const companyId = filterState.companyId ?? sessionCompanyId;
  const setCompanyId = (companyId: number | null) => updateState({ companyId, pageNumber: 1 });

  const runDate = filterState.runDate;
  const setRunDate = (runDate: IsoDatestamp | null) => updateState({ runDate, pageNumber: 1 });

  const searchString = filterState.searchString;
  const setSearchString = useCallback(
    (searchString: string) => updateState({ searchString, pageNumber: 1 }),
    [updateState]
  );

  const [inputBoundSearchString, setInputBoundSearchString] = useState(searchString);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetAppliedSearchString = useCallback(debounce(setSearchString, 1000), [
    setSearchString,
  ]);

  useEffect(() => {
    debouncedSetAppliedSearchString(inputBoundSearchString);
  }, [inputBoundSearchString]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inputBoundSearchString !== searchString) {
      setInputBoundSearchString(searchString);
    }
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeSearchInput = (event: React.ChangeEvent<HTMLInputElement>) =>
    setInputBoundSearchString(event.target.value);

  const [latestResponse, setLatestResponse] = useState<GetDocumentsResponse | null>(null);

  const resetFilters = () => {
    if (!isEqual(filterState, resetDocumentsFilterState)) {
      setFilterState(resetDocumentsFilterState);
      setLatestResponse(null);
    }
  };

  const loadData = () => {
    const query: GetDocumentsQuery = {
      pageNumber,
      resultsPerPage,
      documentCategoryId,
      counterpartId: investorId,
      companyId,
      runDate,
      description: searchString || '',
    };

    getDocumentsApiRequest.makeRequest({
      queryParameters: query,
      onSuccess: setLatestResponse,
    });
  };

  useEffect(() => {
    if (documentCategoryId != null && companyId != null && runDate != null) {
      loadData();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    documentCategoryId,
    investorId,
    companyId,
    runDate,
    searchString,
    pageNumber,
    resultsPerPage,
  ]);

  if (filterLoadError) {
    return <RequestFailedPage error={filterLoadError} />;
  }

  const { inProgress, error } = getDocumentsApiRequest.state;

  return (
    <>
      <PaddedPage>
        <HeaderBar>
          <Header1>{translate('pages.documents.header')}</Header1>
          {isUploadEnabled && (
            <UploadLink data-testid={documentUploadLinkTestId} to="upload">
              <UploadIcon />
              {translate('pages.documents.uploadLink')}
            </UploadLink>
          )}
        </HeaderBar>

        <DocumentsPageContext.Provider
          value={{
            latestResponse,
            inProgress,
            error,
            documentCategoryId,
            setDocumentCategoryId,
            investorId,
            setInvestorId,
            companyId,
            setCompanyId,
            runDate,
            setRunDate,
            inputBoundSearchString,
            onChangeSearchInput,
            resetFilters,
            pageNumber,
            setPageNumber,
            resultsPerPage,
            setResultsPerPage,
            setFilterLoadError,
            loadData,
          }}
        >
          <DesktopOnly>
            <DesktopDocumentsList />
          </DesktopOnly>
          <MobileOnly>
            <MobileDocumentsList />
          </MobileOnly>
        </DocumentsPageContext.Provider>
      </PaddedPage>
    </>
  );
};

const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing16};

  ${Header1} {
    margin-bottom: 0;
  }
`;

const UploadLink = styled(AppLink)`
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  svg {
    margin-right: ${spacing8};
    height: ${spacing16};
    width: ${spacing16};
  }
`;

export const documentsPageCategorySelectTestId = 'documents-page-category-select';
export const documentsPageInvestorSelectTestId = 'documents-page-investor-select';
export const documentsPageCompanySelectTestId = 'documents-page-company-select';
export const documentsPageRunDateDatePickerTestId = 'documents-page-run-date-date-picker';
export const documentsPageSearchInputTestId = 'documents-page-search-input';

export type GetDocumentsQuery = {
  pageNumber: number;
  resultsPerPage: number;
  documentCategoryId: number | null;
  counterpartId: number | null; // Investor ID
  companyId: number | null;
  runDate: IsoDatestamp | null;
  description: string;
};

export type GetDocumentsResponse = {
  totalResults: number;
  documents: Array<DocumentResponse>;
};

export type DocumentResponse = {
  documentId: number;
  description: string;
  fileName: string;
  fund: string | null;
  valueDate: IsoDatestamp | null;
  dateProduced: IsoDatestamp;
  isDownloadBlocked: boolean;
  canDelete: boolean;
};
