import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';

export type GetDocumentCategoriesResponse = {
  documentCategories: Array<DocumentCategoryResponse>;
};

export type DocumentCategoryType = 'AML' | 'SUB' | 'REG' | null;

export type DocumentCategoryResponse = {
  documentCategoryId: number;
  description: string;
  investorUploadPermitted: boolean;
  categoryType: DocumentCategoryType;
};

export const mapGetDocumentCategoriesResponseToSelectOptions = (
  response: GetDocumentCategoriesResponse | null
): SelectOptions<number> =>
  response == null
    ? []
    : response.documentCategories.map((documentCategory) => ({
        value: documentCategory.documentCategoryId,
        label: documentCategory.description,
      }));
