import React, { useMemo, useState } from 'react';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { notBeNull } from '../../validation/commonValidation';
import { Validator } from 'fluentvalidation-ts';
import { haveAllowedFileExtension, notExceedSizeInBytes } from '../../validation/fileValidation';
import { notBeEmpty } from '../../validation/stringValidation';
import { IsoDatestamp } from '../../../helpers/dateTimeHelpers';
import { Form } from '../../../infrastructure/forms/common/Form';
import { FileUploadRoleSelectField } from '../../metadata/role/FileUploadRoleSelectField';
import { DocumentCategorySelectField } from '../../metadata/documentCategory/DocumentCategorySelectField';
import { TextAreaField } from '../../../infrastructure/forms/fields/TextAreaField';
import { DatePickerField } from '../../../infrastructure/forms/fields/DatePickerField';
import { CompanySelectField } from '../../metadata/company/CompanySelectField';
import { CompanyReportGroupSelectField } from '../../metadata/companyReportGroup/CompanyReportGroupSelectField';
import { FilePickerField } from '../../../infrastructure/forms/fields/FilePickerField';
import { ButtonRow } from '../../../infrastructure/interface/buttons/ButtonRow';
import { SubmitButton } from '../../../infrastructure/forms/common/SubmitButton';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import { useMultipartApiRequest } from '../../../infrastructure/api/useMultipartApiRequest';
import { assertNotNull } from '../../../helpers/nullHelpers';
import { useMutuallyExclusiveFields } from '../../../infrastructure/forms/common/useMutuallyExclusiveFields';
import { WebUploadRoleCode } from '../../authentication/WebUploadRoleCode';
import { InvestorSelectFieldWithSearchModal } from '../../metadata/investor/InvestorSelectFieldWithSearchModal';
import { defaultDocumentCategorySelectSettings } from '../../metadata/documentCategory/DocumentCategorySelect';
import { defaultCompanySelectSettings } from '../../metadata/company/CompanySelectSettings';
import { defaultCompanyReportGroupSelectSettings } from '../../metadata/companyReportGroup/CompanyReportGroupSelectSettings';
import { ActionAlert } from '../../../infrastructure/interface/components/ActionAlert';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { defaultAgentDealerSelectSettings } from '../../metadata/AgentDealerSelectSettings';
import { AgentDealerSelectField } from '../../metadata/AgentDealerSelectField';

type Props = {
  allowedFileExtensions: Array<string>;
  maximumUploadSizeInBytes: number;
};

export const ManagerUploadDocumentForm = ({
  allowedFileExtensions,
  maximumUploadSizeInBytes,
}: Props) => {
  const { translate } = useInternationalisation();

  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);

  const [success, setSuccess] = useState(false);

  const [successfulResponse, setSuccessfulResponse] = useState<ManagerUploadDocumentResponse>();
  const [alertIsOpen, setAlertIsOpen] = useState(false);

  const sendNotificationEmailsRequest = usePostJson<SendNotificationEmailsCommand, {}>(
    '/api/documents/UploadDocumentNotificationEmails'
  );

  const onInitiateAction = (documentId: number) => {
    //setAlertIsOpen(false);
    const command: SendNotificationEmailsCommand = { documentId: documentId };

    sendNotificationEmailsRequest.makeRequest({
      requestBody: command,
      onSuccess: () => {
        setAlertIsOpen(false);
      },
      onFailure: () => {
        setAlertIsOpen(false);
      },
    });
  };

  const validator = useMemo(
    () =>
      new ManagerUploadDocumentFormValidator(
        translate,
        allowedFileExtensions,
        maximumUploadSizeInBytes
      ),
    [translate, allowedFileExtensions, maximumUploadSizeInBytes]
  );

  const uploadRequest = useMultipartApiRequest<
    ManagerUploadDocumentCommand,
    ManagerUploadDocumentResponse
  >('/api/documents/ManagerUploadDocument');

  const onSubmit = (
    formModel: ManagerUploadDocumentFormModel,
    formikHelpers: FormikHelpers<ManagerUploadDocumentFormModel>
  ) => {
    let investorId = 0;
    if (formModel.role === 'I' || formModel.role === 'A') {
      investorId = assertNotNull(formModel.investorId, 'investorId');
    }
    if (formModel.role === 'G') {
      investorId = assertNotNull(formModel.agentId, 'agentId');
    }

    const command: ManagerUploadDocumentCommand = {
      role: assertNotNull(formModel.role, 'role'),
      documentCategoryId: assertNotNull(formModel.documentCategoryId, 'documentCategoryId'),
      description: assertNotNull(formModel.description, 'description'),
      validFrom: assertNotNull(formModel.validFrom, 'validFrom'),
      companyId: formModel.companyId,
      companyReportGroupId: formModel.companyReportGroupId,
      investorId: investorId,
      documentFile: assertNotNull(formModel.documentFile[0], 'documentFile'),
    };

    uploadRequest.makeRequest({
      request: command,
      onSuccess: (response) => {
        setSuccessfulResponse(response);
        setAlertIsOpen(true);
        setSuccess(true);
      },
      onFailure: () => formikHelpers.setSubmitting(false),
    });
  };

  if (filterLoadError != null) {
    return (
      <Alert alertType="negative" header={translate('errors.apology')}>
        {filterLoadError}
      </Alert>
    );
  }

  if (success) {
    return (
      <>
        {successfulResponse?.notificationEmailMessage ? (
          <ActionAlert
            alertType="positive"
            isOpen={alertIsOpen}
            title={translate('pages.uploadDocument.notificationEmails.title')}
            message={translate('pages.uploadDocument.notificationEmails.message')}
            cancelButtonText={translate('pages.uploadDocument.notificationEmails.cancelButtonText')}
            onRequestClose={() => setAlertIsOpen(false)}
            confirmButtonText={translate(
              'pages.uploadDocument.notificationEmails.confirmButtonText'
            )}
            onInitiateAction={() => onInitiateAction(successfulResponse.documentId)}
            actionInProgress={sendNotificationEmailsRequest.state.inProgress}
            actionError={sendNotificationEmailsRequest.state.error}
          />
        ) : (
          <></>
        )}
        <Alert
          alertType="positive"
          header={translate('pages.uploadDocument.successMessage.header')}
        >
          {translate('pages.uploadDocument.successMessage.body')}
        </Alert>
      </>
    );
  }

  return (
    <Panel>
      <Formik<ManagerUploadDocumentFormModel>
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validate={validator.validate}
      >
        {(formikProps) => (
          <FormComponent
            translate={translate}
            setFilterLoadError={setFilterLoadError}
            formikProps={formikProps}
            allowedFileExtensions={allowedFileExtensions}
            maximumUploadSizeInBytes={maximumUploadSizeInBytes}
            uploadRequestError={uploadRequest.state.error}
          />
        )}
      </Formik>
    </Panel>
  );
};

type FormComponentProps = {
  translate: TranslateFunction;
  setFilterLoadError: (error: string) => void;
  formikProps: FormikProps<ManagerUploadDocumentFormModel>;
  allowedFileExtensions: Array<string>;
  maximumUploadSizeInBytes: number;
  uploadRequestError: string | null;
};

const FormComponent = ({
  translate,
  setFilterLoadError,
  formikProps,
  allowedFileExtensions,
  maximumUploadSizeInBytes,
  uploadRequestError,
}: FormComponentProps) => {
  useMutuallyExclusiveFields<ManagerUploadDocumentFormModel>(['companyId', 'companyReportGroupId']);

  const showInvestor = formikProps.values.role === 'A' || formikProps.values.role === 'I';
  const showAgent = formikProps.values.role === 'G';

  return (
    <Form>
      <FileUploadRoleSelectField
        fieldName="role"
        label={translate('pages.uploadDocument.labels.role')}
        onError={setFilterLoadError}
      />
      <DocumentCategorySelectField
        settings={defaultDocumentCategorySelectSettings}
        fieldName="documentCategoryId"
        label={translate('pages.uploadDocument.labels.documentCategory')}
        onError={setFilterLoadError}
        defaultToFirstOption={true}
      />
      <TextAreaField
        fieldName="description"
        label={translate('pages.uploadDocument.labels.description')}
      />
      <DatePickerField
        fieldName="validFrom"
        label={translate('pages.uploadDocument.labels.validFrom')}
      />
      <CompanySelectField
        fieldName="companyId"
        label={translate('pages.uploadDocument.labels.company')}
        onError={setFilterLoadError}
        settings={{
          ...defaultCompanySelectSettings,
          documentCategoryId: formikProps.values.documentCategoryId,
          amlCheckId: null,
        }}
        defaultToFirstOption={true}
      />
      <CompanyReportGroupSelectField
        fieldName="companyReportGroupId"
        label={translate('pages.uploadDocument.labels.companyReportGroup')}
        onError={setFilterLoadError}
        settings={{ ...defaultCompanyReportGroupSelectSettings, isForUpload: true }}
      />
      {showInvestor ? (
        <InvestorSelectFieldWithSearchModal
          fieldName="investorId"
          label={translate('pages.uploadDocument.labels.investor')}
          onError={setFilterLoadError}
          settings={{ forTransacting: false }}
        />
      ) : (
        <></>
      )}
      {showAgent ? (
        <AgentDealerSelectField
          fieldName="agentId"
          fieldLabel={translate('pages.uploadDocument.labels.agent')}
          settings={{ ...defaultAgentDealerSelectSettings, isAgent: true }}
          onError={setFilterLoadError}
          defaultToFirstOption={true}
        />
      ) : (
        <></>
      )}
      <FilePickerField
        fieldName="documentFile"
        allowMultiple={false}
        allowedFileTypes={allowedFileExtensions}
        label={translate('pages.uploadDocument.labels.documentFile')}
        disabled={allowedFileExtensions.length === 0 || maximumUploadSizeInBytes === 0}
      />
      <ButtonRow rightAligned={true} withMarginTop={true}>
        <SubmitButton
          submittingText={translate('pages.uploadDocument.submitButton.submittingText')}
        >
          {translate('pages.uploadDocument.submitButton.text')}
        </SubmitButton>
      </ButtonRow>
      {uploadRequestError && (
        <Alert alertType="negative" header={translate('errors.apology')} withMarginTop={true}>
          {uploadRequestError}
        </Alert>
      )}
    </Form>
  );
};

export type ManagerUploadDocumentFormModel = {
  role: WebUploadRoleCode | null;
  documentCategoryId: number | null;
  description: string;
  validFrom: IsoDatestamp | null;
  companyId: number | null;
  companyReportGroupId: number | null;
  investorId: number | null;
  agentId: number | null;
  documentFile: Array<File>; // Needs to be an array as that's what the file picker exposes
};

export type ManagerUploadDocumentCommand = {
  role: WebUploadRoleCode;
  documentCategoryId: number;
  description: string;
  validFrom: IsoDatestamp;
  companyId: number | null;
  companyReportGroupId: number | null;
  investorId: number;
  documentFile: File;
};

export type ManagerUploadDocumentResponse = {
  documentId: number;
  notificationEmailMessage: boolean;
};

type SendNotificationEmailsCommand = {
  documentId: number;
};

const initialFormValues: ManagerUploadDocumentFormModel = {
  role: 'A',
  documentCategoryId: null,
  description: '',
  validFrom: null,
  companyId: null,
  companyReportGroupId: null,
  investorId: 0,
  agentId: 0,
  documentFile: [],
};

class ManagerUploadDocumentFormValidator extends Validator<ManagerUploadDocumentFormModel> {
  constructor(
    translate: TranslateFunction,
    allowedFileExtensions: Array<string>,
    maximumUploadSizeInBytes: number
  ) {
    super();

    this.ruleFor('role').must(notBeNull(translate));

    this.ruleFor('documentCategoryId').must(notBeNull(translate));

    this.ruleFor('description').must(notBeEmpty(translate));

    this.ruleFor('validFrom').must(notBeNull(translate));

    this.ruleFor('companyId')
      .notNull()
      .withMessage(translate('pages.uploadDocument.validation.fundOrFundGroup'))
      .when((formModel) => formModel.companyReportGroupId == null);

    this.ruleFor('companyReportGroupId')
      .notNull()
      .withMessage(translate('pages.uploadDocument.validation.fundOrFundGroup'))
      .when((formModel) => formModel.companyId == null);

    this.ruleFor('investorId').must(notBeNull(translate));

    this.ruleFor('documentFile')
      .must((files) => files.length === 1)
      .withMessage(translate('validation.requiredField'))
      .must(haveAllowedFileExtension(translate, allowedFileExtensions))
      .must(notExceedSizeInBytes(translate, maximumUploadSizeInBytes));
  }
}
