import React, { useContext } from 'react';
import {
  documentsPageCategorySelectTestId,
  documentsPageCompanySelectTestId,
  documentsPageInvestorSelectTestId,
  documentsPageRunDateDatePickerTestId,
  documentsPageSearchInputTestId,
} from './Documents';
import { FieldLabel } from '../../infrastructure/forms/common/FieldLabel';
import {
  defaultDocumentCategorySelectSettings,
  DocumentCategorySelect,
} from '../metadata/documentCategory/DocumentCategorySelect';
import { IfUserHasRole } from '../authentication/UserRoles';
import { without } from 'lodash';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { CompanySelect } from '../metadata/company/CompanySelect';
import { ResetFiltersButton } from '../filters/ResetFiltersButton';
import { Spinner } from '../../infrastructure/interface/components/Spinner';
import { Panel } from '../../infrastructure/interface/components/Panel';
import { Header2 } from '../../infrastructure/interface/components/Headers';
import { PaginatedTable } from '../../infrastructure/interface/tables/PaginatedTable';
import {
  NoResultsRow,
  Table,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../infrastructure/interface/tables/Table';
import styled from 'styled-components/macro';
import { spacing16, spacing32, spacing64 } from '../../styling/design/spacing';
import { Input } from '../../infrastructure/interface/forms/Input';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { DocumentsPageContext } from './DocumentsPageContext';
import { SearchPlaceholder } from '../../infrastructure/interface/components/SearchPlaceholder';
import { DocumentLink } from './DocumentLink';
import { DocumentDeleteButton } from './DocumentDeleteButton';
import { DatePicker } from '../../infrastructure/interface/forms/DatePicker';
import { RequestFailedAlert } from '../../infrastructure/api/RequestFailedAlert';
import { InvestorSelectWithSearchModal } from '../metadata/investor/InvestorSelectWithSearchModal';
import { InvestorSelect } from '../metadata/investor/InvestorSelect';
import { defaultCompanySelectSettings } from '../metadata/company/CompanySelectSettings';

export const DesktopDocumentsList = () => {
  const { translate, formatDate } = useInternationalisation();

  const {
    latestResponse,
    inProgress,
    error,
    documentCategoryId,
    setDocumentCategoryId,
    investorId,
    setInvestorId,
    companyId,
    setCompanyId,
    runDate,
    setRunDate,
    inputBoundSearchString,
    onChangeSearchInput,
    resetFilters,
    pageNumber,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    setFilterLoadError,
    loadData,
  } = useContext(DocumentsPageContext);

  return (
    <>
      <FilterComponentsContainer>
        <div>
          <FieldLabel>{translate('pages.documents.filters.documentCategory')}</FieldLabel>
          <DocumentCategorySelect
            value={documentCategoryId}
            onChange={setDocumentCategoryId}
            data-testid={documentsPageCategorySelectTestId}
            onError={setFilterLoadError}
            settings={{ ...defaultDocumentCategorySelectSettings, includeAllCategory: true }}
            defaultToFirstOption={true}
          />
        </div>
        <IfUserHasRole userRole={without(allNonAdminUserRoles, 'Investor')}>
          <div>
            <FieldLabel>{translate('pages.documents.filters.investor')}</FieldLabel>
            <IfUserHasRole userRole={['Manager', 'Advisor']}>
              <InvestorSelectWithSearchModal
                settings={{ forTransacting: false }}
                value={investorId}
                onChange={setInvestorId}
                data-testid={documentsPageInvestorSelectTestId}
                onError={setFilterLoadError}
                defaultToFirstOption={true}
              />
            </IfUserHasRole>
            <IfUserHasRole userRole="Consolidated Investor">
              <InvestorSelect
                settings={{ forTransacting: false }}
                value={investorId}
                onChange={setInvestorId}
                data-testid={documentsPageInvestorSelectTestId}
                onError={setFilterLoadError}
                defaultToFirstOption={true}
              />
            </IfUserHasRole>
          </div>
        </IfUserHasRole>
        <div>
          <FieldLabel>{translate('pages.documents.filters.company')}</FieldLabel>
          <CompanySelect
            value={companyId}
            onChange={setCompanyId}
            data-testid={documentsPageCompanySelectTestId}
            onError={setFilterLoadError}
            settings={{
              ...defaultCompanySelectSettings,
              includeBlank: true,
              includeAllFunds: true,
              rememberCompanyId: true,
            }}
            defaultToFirstOption={true}
          />
        </div>
        <div>
          <FieldLabel>{translate('pages.documents.filters.runDate')}</FieldLabel>
          <DatePicker
            data-testid={documentsPageRunDateDatePickerTestId}
            value={runDate}
            onChange={setRunDate}
          />
        </div>

        <ResetFiltersButton resetFilters={resetFilters} />
      </FilterComponentsContainer>

      {error != null && (
        <RequestFailedAlert
          error={error}
          retry={loadData}
          withMarginTop={true}
          withMarginBottom={true}
        />
      )}

      {latestResponse == null ? (
        inProgress ? (
          <SpinnerContainer>
            <Spinner size="xlarge" />
          </SpinnerContainer>
        ) : (
          <SearchPlaceholder message={translate('pages.documents.placeholderMessage')} />
        )
      ) : (
        <Panel>
          <PanelHeaderRow>
            <Header2>{translate('pages.documents.panelHeader')}</Header2>
            <SearchInput
              onChange={onChangeSearchInput}
              value={inputBoundSearchString}
              placeholder={translate('pages.documents.filters.searchString')}
              data-testid={documentsPageSearchInputTestId}
            />
          </PanelHeaderRow>
          <PaginatedTable
            showLoadingOverlay={inProgress}
            resultsPerPage={resultsPerPage}
            totalResultsCount={latestResponse?.totalResults || 0}
            onChangeResultsPerPage={setResultsPerPage}
            currentPageNumber={pageNumber}
            onChangeCurrentPageNumber={setPageNumber}
          >
            <Table>
              <THead>
                <Tr>
                  <Th>{translate('pages.documents.table.headings.documentDescription')}</Th>
                  <Th>{translate('pages.documents.table.headings.fund')}</Th>
                  <Th>{translate('pages.documents.table.headings.valueDate')}</Th>
                  <Th>{translate('pages.documents.table.headings.dateProduced')}</Th>
                  <Th>&nbsp;</Th>
                </Tr>
              </THead>
              <TBody>
                {latestResponse.documents.map((document) => (
                  <Tr key={document.documentId}>
                    <Td title={document.fileName}>
                      {document.isDownloadBlocked ? (
                        <>{document.description}</>
                      ) : (
                        <DocumentLink document={document} />
                      )}
                    </Td>
                    <Td>{document.fund}</Td>
                    <Td>{document.valueDate == null ? '' : formatDate(document.valueDate)}</Td>
                    <Td>{formatDate(document.dateProduced)}</Td>
                    <Td>
                      {document.canDelete && (
                        <DocumentDeleteButton
                          documentId={document.documentId}
                          onDocumentDeleted={loadData}
                          size="medium"
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
                {latestResponse.documents.length === 0 && <NoResultsRow colSpan={5} />}
              </TBody>
            </Table>
          </PaginatedTable>
        </Panel>
      )}
    </>
  );
};

const FilterComponentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${spacing16};
  margin-bottom: ${spacing32};
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing64};
`;

const PanelHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${spacing32};

  ${Header2} {
    margin: 0;
  }
`;

const SearchInput = styled(Input)`
  width: 400px;
`;
